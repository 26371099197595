.current-benefits-container {
  margin: 1rem;
}

.current-benefits-header {
  margin-top: 4rem;
  text-align: center;
}

.long-near-term-header {
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: normal;
  font-family: 'Roboto Slab', serif;
}

.program-name {
  padding: 0;
  color: var(--primary-color);
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 1.25rem;
}

.category-section-container {
  margin-bottom: 3rem;
}

.header-and-programs-container {
  margin: 3rem 0;
}

@media (max-width: 700px) {
  .co-benefits-container {
    font-size: 1rem;
  }
  .co-benefits-header {
    margin-top: 2rem;
  }

  .long-near-term-header {
    display: flex;
    margin-bottom: 2rem;
  }
}

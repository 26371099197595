.result-program-container {
  position: relative;
  border: 2px solid var(--secondary-color);
  margin-bottom: 1rem;
}

.result-program-container.passed {
  background-color: #d9ead3;
}

.result-program-container.failed {
  background-color: #ea9999;
}

.result-program-container hr {
  background-color: var(--secondary-color);
  width: 98%;
  margin: 0 auto;
  height: 2px;
  border: none;
}

.result-program-flags-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
}

.result-program-more-info {
  width: 70%;
  display: block;
  line-height: normal;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 1.25rem;
  padding: 1.25rem 2rem;
}

.result-program-more-info a {
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
  border-radius: 0.75rem;
  position: absolute;
  top: 0.75rem;
  right: 3rem;
  padding: 0.5rem 0.75rem;
  background-color: var(--primary-color);
}

.result-program-details {
  font-size: 0.9rem;
  display: inline-block;
  font-weight: 100;
  line-height: normal;
  width: 50%;
  padding: 1rem 2rem;
}

.result-program-details strong {
  display: block;
}

.result-program-subheader {
  font: Open Sans, sans-serif;
}

.new-program-flag {
  font-weight: bold;
  font-family: 'Roboto Slab', serif;
  background-color: #00b213;
  padding: 0 0.5rem;
  color: #fff;
  border-radius: 0 0 0.75rem 0;
  font-size: 0.8125rem;
  top: 0;
  z-index: 100;
  text-wrap: nowrap;
}

.low-confidence-flag {
  font-weight: bold;
  font-family: 'Roboto Slab', serif;
  background-color: var(--hover-color);
  padding: 0 0.5rem 0 1rem;
  color: var(--primary-color);
  border-radius: 0 0 0.75rem 0;
  font-size: 0.8125rem;
  top: 0;
  z-index: 99;
}

h1.header-text-bottom {
  font-size: 1.5rem;
  text-wrap: wrap;
}

@media (min-width: 775px) {
  .results-header-programs-count-text {
    height: 3rem;
    border-radius: 0.5rem;
    background-color: var(--primary-color);
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;
  }

  .category-heading-column {
    flex: 1;
  }

  h2.category-heading-text-style.normal-weight {
    display: block;
    text-align: right;
    width: 100%;
  }

  .results-data-cell {
    display: flex;
    flex-direction: column-reverse;
    margin: 0.5rem 1rem;
  }

  .result-program-container:last-child {
    margin-bottom: 3rem;
  }

  .column-row {
    display: flex;
    flex-direction: row;
  }

  .results-header {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
  }

  .results-header-programs-count {
    font-size: x-large;
    font-weight: 700;
    margin: auto;
    font-family: 'Roboto Slab', serif;
    display: flex;
    margin-right: 0.5rem;
  }

  .results-header-values {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: var(--primary-color);
    border-top: 1.5px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
  }

  .results-header-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: var(--primary-color);
  }

  .result-program-container {
    position: relative;
    display: flex;
    align-items: center;
  }

  .result-program-more-info {
    width: 40%;
    vertical-align: top;
    line-height: normal;
  }

  .result-program-details {
    font-weight: 400;
    width: 20%;
    vertical-align: text-bottom;
    color: var(--midBlue-color);
  }

  .result-program-container hr {
    display: none;
  }

  .result-program-more-info a {
    position: absolute;
    top: 1.1rem;
    right: 3rem;
    padding: 0.5rem 1rem;
  }

  .result-program-details strong {
    display: inline-block;
    color: var(--primary-color);
  }
}

@media (max-width: 775px) {
  .results-header-programs-count-text {
    width: 7rem;
    height: 7rem;
    background-color: var(--primary-color);
    color: white;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    text-align: center;
    padding-top: 0.75rem;
    line-height: normal;
    padding-bottom: 0.5rem;
    margin-right: 1rem;
  }

  .column-row {
    display: flex;
    flex-direction: column;
  }

  .results-header {
    display: flex;
    flex-direction: row;
  }

  .results-header-programs-count {
    font-size: xx-large;
    font-weight: 700;
    padding-bottom: 0.25rem;
    font-family: 'Roboto Slab', serif;
  }

  .results-header-values {
    font-family: 'Roboto Slab', serif;
    font-weight: 700;
    color: var(--primary-color);
    border-bottom: 1.5px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
  }

  .results-header-label {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: var(--primary-color);
  }

  .results-header {
    display: flex;
    flex-direction: row;
  }
}

p {
  font-family: 'Open Sans', sans-serif;
  color: black;
}

.question-header-container {
  margin-bottom: 1.375rem;
}

.question-header {
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  color: var(--secondary-color);
}

.question-secondary-header {
  font-size: 1.2rem;
  font-family: 'Roboto Slab', serif;
  font-weight: bold;
  color: var(--primary-color);
}

.question-label {
  font-size: 1.375rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  font-family: 'Open Sans', serif;
  color: black;
}

.question-description {
  margin-bottom: 1rem;
  font-family: 'Open Sans', serif;
}

.help-text {
  font-family: 'Open Sans', serif;
  font-size: 1rem;
  font-weight: 200;
  color: black;
  margin-bottom: 1rem;
}

.prev-button {
  margin-right: 2.25rem !important;
}

.prev-save-continue-buttons,
.prev-continue-results-buttons {
  margin-top: 1rem;
}

.household-data-q-underline {
  font-size: 2rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #505c66;
}

.household-data-sub-header {
  font-size: 1.5rem;
  line-height: 1.5rem;
  margin-bottom: 1rem;
}

.household-data-sub2-header {
  margin-bottom: 1rem;
}

.underline-id {
  text-decoration: underline;
}

.remember-disclaimer-label {
  font-size: 1.25rem;
  margin-bottom: 8px;
}

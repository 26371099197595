button.twoOneOne-share-close {
  position: absolute;
  right: 0;
  top: 0;
}

[dir='rtl'] button.twoOneOne-share-close {
  left: 0;
  right: unset;
}

.twoOneOne-share-container {
  padding: 2rem;
}

button.copy-link-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.twoOneOne-share-header {
  text-align: center;
  font-size: 1.1rem;
  margin-bottom: 5px;
}

.twoOneOne-share-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.twoOneOne-row-group {
  margin-top: 0.4rem;
  white-space: nowrap;
}

.twoOneOne-icon-name {
  overflow: hidden;
  white-space: nowrap;
  margin-left: 0;
  max-width: 0px;
  transition: max-width 1s, margin 0.3s;
  font-size: 0.88rem;
  cursor: pointer;
  color: #fff;
}

.twoOneOne-fb {
  background-color: #3b5998;
  color: white;
}

.twoOneOne-twitter {
  background-color: #00aced;
  color: white;
}

.twoOneOne-gray {
  background-color: #686565;
  color: white;
}

.twoOneOne-whatsApp {
  background-color: #25d366;
  color: white;
}

.twoOneOne-messenger {
  background-color: #2196f3;
  color: white;
}

.cobranded-logo {
  max-width: 20rem;
  display: block;
}

.MuiTypography-root.MuiLink-root.twoOneOneMenuLink {
  font-weight: 600;
  font-size: 0.7rem;
}

.twoOneOne-globe-icon {
  color: var(--primary-color);
}

.twoOneOne-header-container {
  background-color: #ffffff;
  height: 5rem;
  width: 100%;
}

#twoOneOne-select-language {
  padding: 0;
  color: #005191;
  font-weight: 600;
  font-size: 0.8125rem;
  margin-right: 1.5rem;
  text-transform: uppercase;
}

#twoOneOne-nav-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-width: var(--main-max-width);
  margin: auto;
  height: 100%;
  padding: 0 1rem;
}

#twoOneOne-select-language:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}

button.hamburger-icon {
  display: none;
}

#hamburger-drawer {
  display: none;
}

@media (max-width: 1280px) {
  button.hamburger-icon {
    display: flex;
  }

  #hamburger-drawer {
    display: flex;
    background-color: #fff;
    position: relative;
    z-index: 1000;
    padding: 1rem;
    align-items: flex-end;
    gap: 1rem;
    height: 100vh;
  }

  div.twoOneOne-desktop-links {
    display: none;
  }
}

@media (max-width: 650px) {
  .cobranded-logo {
    width: 90%;
  }
}

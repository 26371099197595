.button211 {
  display: flex;
  background-color: var(--primary-color);
  border-radius: 1.8rem;
  color: white;
  font-family: 'Roboto Slab', serif;
  font-weight: 700;
  font-size: 1.25rem;
  text-decoration: none;
  padding: 0.5rem 2rem;
  align-self: center;
  margin-top: 0.5rem;
}

.button211:hover {
  background-color: white;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  cursor: pointer;
  margin-bottom: -2px;
}

.hover-button:hover {
  background-color: white;
}

.help-text-for-211-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 3rem;
}

.help-text-for-211-button-font {
  display: flex;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
}
